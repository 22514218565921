import goalkeeper from '../assets/P.svg';
import defensor from '../assets/D.svg';
import midfielder from '../assets/C.svg';
import striker from '../assets/A.svg';

const PlayerCard = (player) => {
    if(player==undefined)
      return (<></>);
    return (
        <div className="playerCard">
        <span role="img" style={{ marginRight: '1rem' }}>
          {(player!=={} && player!== undefined && player.ruolo === 'P' && <img alt="" src={goalkeeper} />) ||
            (player!=={} && player!== undefined  && player.ruolo === 'D' && <img alt="" src={defensor} />) ||
            (player!=={} && player!== undefined  && player.ruolo === 'C' && <img alt="" src={midfielder} />) ||
            (player!=={} && player!== undefined  && player.ruolo === 'A' && <img alt="" src={striker} />)}
        </span>
        <p style={{ fontStyle: 'bold' }}>
          {player !== undefined &&
          player.nome !== undefined &&
          player.squadra !== undefined &&
          player.nome !== '' &&
            player.squadra !== '' &&
            `${player.nome} (${player.squadra}) `}
        </p>
      </div>
    );
  };

  export default PlayerCard;
  