import { Component, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';
import icon from './assets/icon.svg';
import {json} from './players';
import PlayerCard from './components/PlayerCard';
import './App.css';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Pagination from 'react-bootstrap/Pagination';
import ListGroup from 'react-bootstrap/ListGroup';
import Stack from 'react-bootstrap/Stack';

const ExtractedList=({show,players,placement,onHide})=>{
    const [state,setState]= useState({
        filter: "",
        active: 0
    });
    
    const changeList=()=>{
        let newList= [[], [], [], []];

        for(const indexCategory in players){
            for(const indexPlayer in players[indexCategory]){
                const player= players[indexCategory][indexPlayer];
                if(player.nome.toLowerCase().includes(state.filter.toLowerCase())){
                    newList[indexCategory].push(player);
                }
            }
        }
        return newList;
    }
    let letters = ['P','D','C','A'];
    let items = [];
    for (let number = 0; number < letters.length; number++) {
    items.push(
        <Pagination.Item key={letters[number]} active={number === state.active} onClick={()=>{setState({filter:state.filter,active:number})}}>
        {letters[number]}
        </Pagination.Item>,
    );
    }

    return (<Offcanvas show={show} placement={placement} onHide={onHide}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{(placement=="end" && "Lista giocatori estratti")||(placement=="start" && "Lista giocatori non estratti")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <Stack gap={3}>
                <Pagination>
                    {items}
                </Pagination>
                <Form className="d-flex ">
                    <Form.Control
                    type="search"
                    placeholder={(placement=="end" && "Cerca giocatori estratti")||placement=="start" && "Cerca giocatori non estratti"}
                    className="me-2"
                    aria-label="Search"
                    onChange={(e)=>setState({filter:e.target.value,active:state.active})}
                    />
                </Form>
                
                <div className='align-items-start'>{
                  <ListGroup>{
                      changeList()[state.active].map((it,index)=>{
                          return (<ListGroup.Item><PlayerCard key={index} {...it}/></ListGroup.Item>);
                      })
                  }</ListGroup>
                }</div>
            </Stack>
        </Offcanvas.Body>
      </Offcanvas>);
}

class Hello extends Component{

  constructor(props){
    super(props);
    const players=[[], [], [], []]
    for (const player in json) {
      // eslint-disable-next-line default-case
      switch (json[player].ruolo) {
        case 'P':
          players[0].push(json[player]);
          break;
        case 'D':
          players[1].push(json[player]);
          break;
        case 'C':
          players[2].push(json[player]);
          break;
        case 'A':
          players[3].push(json[player]);
          break;
      }
    }
    this.state={
      extracted: {},
      extractedPlayers: [[], [], [], []],
      playersavailable: players,
      showexctracted: false,
      showavailable: false
    };  
  }

  extractPlayer=() => {
    const myselect= document.getElementById(
      'my-select'
    );
    const roleindex= parseInt(myselect.value, 10) - 1;
    const remainingplayers=this.state.playersavailable[roleindex].length;
    let random= -1;
    if(remainingplayers>0){
      random = Math.floor(
        Math.random() * remainingplayers
      );  
    }else{
      return;
    }

    const player = this.state.playersavailable[roleindex][random];
    
    console.log("Con il numero... "+random)
    console.log("...estratto "+player.nome)
    const newextractedlist = [];
    for(const arr in this.state.extractedPlayers){
      if(arr==roleindex){
        newextractedlist.push([...this.state.extractedPlayers[roleindex],player]);
      }else{
        newextractedlist.push([...this.state.extractedPlayers[arr]]);
      }
    }

    
    const newplayersavailable = [];
    for(const arr in this.state.playersavailable){
      if(arr==roleindex){
        newplayersavailable.push([...this.state.playersavailable[roleindex].slice(0,random),...this.state.playersavailable[roleindex].slice(random+1)]);
      }else{
        newplayersavailable.push([...this.state.playersavailable[arr]]);
      }
    }

    this.setState({
      extracted: player,
      extractedPlayers: newextractedlist,
      playersavailable: newplayersavailable
    });

  }

  render (){
    return (<Container>
        {
          <ExtractedList show={this.state.showavailable} players={this.state.playersavailable} placement={"start"} onHide={()=>{this.setState({showavailable:false})}}/>
        }
        {
          <ExtractedList show={this.state.showexctracted} players={this.state.extractedPlayers} placement={"end"} onHide={()=>{this.setState({showexctracted:false})}}/>
        }
        <div>
          <Stack gap={3}>
            <div className="Hello">
              <img width="200" alt="icon" src={icon} />
            </div>
            <div className='text-center'>
              <p>Seleziona un reparto da cui estrarre i giocatori:</p>
            </div>
            <Form.Select id="my-select">
              <option value="1">Portieri</option>
              <option value="2">Difensori</option>
              <option value="3">Centrocampisti</option>
              <option value="4">Attaccanti</option>
            </Form.Select>
            <PlayerCard {...this.state.extracted} />
          </Stack>
          <Stack gap={3}>
            <a>
              <button
                className='active'
                type="button"
                onClick={this.extractPlayer}
              >
                
                Estrai un giocatore 
                <span role="img" aria-label="books">
                  🎲
                </span>
              </button>
            </a>
            <Stack direction='horizontal' gap={1}>
              <a>
                <button type='button' onClick={()=>{this.setState({showavailable:true})}}>
                  Giocatori non estratti 👈🏻
                </button>
              </a>
              <a>
                <button type='button' onClick={()=>{this.setState({showexctracted:true})}}>
                Giocatori estratti 👉🏻
                </button>
              </a>
            </Stack>
          </Stack>
        </div>
  </Container>);
  };
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Hello />} />
      </Routes>
    </Router>
  );
}
